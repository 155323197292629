<template>
  <div ref="homeWrap" :class="{ home_page_news: isShowNews }" class="home_page">
    <div class="user_content">
      <div class="user_info">
        <img alt="" class="user_bg" src="../../common/imgs/home/user_bg.png" />
        <div class="user">
          <router-link :to="{ path: '/home/personal' }">
            <img
              :src="`${userMsg.photo ? $PicPrefix + userMsg.photo : defPhoto}`"
              class="curp"
            />
          </router-link>
        </div>
        <router-link :to="{ path: '/home/personal' }">
          <p class="tac user_name curp">
            {{ userMsg.realName || userMsg.loginName }}
          </p>
          <p class="tac mt2 fz14 main_theme_color_999">
            {{ $t("home.home.ysnh") }}~
          </p>
        </router-link>
        <div class="clinic">
          <el-select
            v-model="selectedClinicId"
            :placeholder="$t('common.message.qxz')"
            clearable
            style="width: 2.36rem; height: 0.4rem"
            @change="clinicChange(arguments[0])"
            @clear="selectClear"
          >
            <el-option
              v-for="item in clinicList"
              :key="item.clinicId"
              :label="item.clinicName"
              :value="item.clinicId"
            ></el-option>
          </el-select>
        </div>
        <div class="case">
          <div @click="jumpCase('to_handle')">
            <span>{{ casesCountObj["to_handle"] }}</span>
            <div>{{ $t("home.home.dtjbl") }}</div>
          </div>
          <div class="vertical_moulding"></div>
          <div @click="jumpCase('curing')">
            <span>{{ casesCountObj["curing"] }}</span>
            <div>{{ $t("home.home.zlzbl") }}</div>
          </div>
          <div class="vertical_moulding"></div>
          <div @click="jumpCase('done') / home">
            <span>{{ casesCountObj["done"] }}</span>
            <div>{{ $t("casesDetail.casesDetail.ywc") }}</div>
          </div>
        </div>
      </div>
      <div class="case_info">
        <div class="tabs">
          <div>
            <img
              :src="
                `/static/images/home/daichuli${
                  $route.query.caseType === 'pending' || !$route.query.caseType
                    ? '1'
                    : ''
                }.png`
              "
              alt=""
              style="width: 0.3rem; height: 0.28rem"
            />
            <div class="ml10 fz16">{{ $t("home.home.dtjbl") }}</div>
          </div>
          <div class="look_all">
            <span @click="lookAll('to_handle')">{{
              $t("home.home.ckqb")
            }}</span>
          </div>
        </div>
        <template v-if="casesObj.toPending && casesObj.toPending.length > 0">
          <div
            v-for="(item, caseIdx) in casesObj.toPending"
            :key="caseIdx"
            class="case"
            @click="detailJump(item)"
          >
            <div class="case_message">
              <div
                :style="{
                  backgroundImage: `url('${
                    item.userInfo && item.userInfo.photo
                      ? $PicPrefix + item.userInfo.photo
                      : defPhoto
                  }')`
                }"
                class="head_photo"
              ></div>
              <p class="name">{{ item.userInfo && item.userInfo.realName }}</p>
            </div>
            <div class="product_name main_theme_color_999">
              {{ item.productName }}
            </div>
            <div class="case_number">{{ item.caseNumber }}</div>
            <div
              v-if="
                item.nextProcessingFlag === '1' ||
                  statusList.includes(item.nextStatus)
              "
              class="case_status main_theme_color"
            >
              {{
                item.wearNotifyFlag === "1" ||
                (statusList.includes(item.nextStatus) &&
                  item.receiveNotifyFlag === "0")
                  ? $t("cases.cases.zlz")
                  : nextStatusObj[item.nextStatus]
              }}
            </div>
            <div
              v-else-if="item.nextProcessingFlag === '0'"
              class="case_status main_theme_color"
            >
              {{ nextStatusObj[item.nextStatus] }}
            </div>
            <p>
              <span class="li_r_right_icon"></span>
            </p>
          </div>
        </template>
        <div v-else class="empty_type">
          <p class="empty_img"></p>
          <span class="mt24 main_theme_color_666 fz14">{{
            $t("home.home.nmhydcl")
          }}</span>
        </div>
        <div class="bottom_info">
          <div class="create-btn" @click="inquireProductList">
            <img
              alt=""
              class="curp add_btn"
              src="../../common/imgs/home/add_icon.png"
            />
            <div class="main_theme_color_333 curp ml10 add_btn">
              {{ $t("home.home.xzbl") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="case_content">
      <div class="case_list posr">
        <div class="case_list_header">
          <div class="flex-x-y-c">
            <img alt="" src="../../common/imgs/home/case_list_icon.png" />
            <p class="main_theme_color_333 fz16 ml10">
              {{ $t("home.home.dtjbls") }}
            </p>
            <div
              :class="{
                expressage_header_tabs_total: +casesObj.toSubmitListTotal < 10,
                expressage_header_tabs_total_two:
                  +casesObj.toSubmitListTotal <= 99 &&
                  +casesObj.toSubmitListTotal > 9,
                expressage_header_tabs_total_three:
                  +casesObj.toSubmitListTotal > 99
              }"
              class="expressage_header_tabs_total_main flex-x-y-c"
            >
              {{
                +casesObj.toSubmitListTotal > 99
                  ? "99+"
                  : casesObj.toSubmitListTotal
              }}
            </div>
          </div>
          <div class="main_theme_color curp" @click="lookAll('precuring')">
            {{ $t("home.home.ckgd") }}
          </div>
        </div>
        <template
          v-if="casesObj.toSubmitList && casesObj.toSubmitList.length > 0"
        >
          <div
            v-for="(item, caseIdx) in casesObj.toSubmitList"
            :key="caseIdx"
            class="case_list_item curp"
            @click="goSubmit(item)"
          >
            <div class="case_message">
              <div
                :style="{
                  backgroundImage: `url('${
                    item.userInfo && item.userInfo.photo
                      ? $PicPrefix + item.userInfo.photo
                      : defPhoto
                  }')`
                }"
                class="head_photo"
              ></div>
              <div>
                <div class="name txt-ellipsis">
                  {{ item.userInfo && item.userInfo.realName }}
                </div>
                <div class="case_number ml10 txt-ellipsis">
                  {{ item.caseNumber }}
                </div>
              </div>
            </div>
            <div class="product_name main_theme_color_999">
              {{ item.productName }}
            </div>
            <div class="flex-x-y-c ml20">
              <span class="li_r_right_icon"></span>
            </div>
          </div>
        </template>
        <div v-else class="empty_type">
          <p class="empty_img to_submit_img"></p>
          <span class="mt24 main_theme_color_666 fz14">{{
            $t("home.home.nhmydtj")
          }}</span>
        </div>
      </div>
      <div class="case_list ml20 posr">
        <div class="case_list_header">
          <div class="flex-x-y-c">
            <img alt="" src="../../common/imgs/home/case_list_icon.png" />
            <p
              :style="language === 'en_US' ? 'width: 1.8rem' : ''"
              class="main_theme_color_333 fz16 ml10"
            >
              {{ $t("home.home.dpzfa") }}
            </p>
            <div
              :class="{
                expressage_header_tabs_total: +wrapRatifyListTotal < 10,
                expressage_header_tabs_total_two:
                  +wrapRatifyListTotal <= 99 && +wrapRatifyListTotal > 9,
                expressage_header_tabs_total_three: +wrapRatifyListTotal > 99
              }"
              class="expressage_header_tabs_total_main flex-x-y-c"
            >
              {{ +wrapRatifyListTotal > 99 ? "99+" : wrapRatifyListTotal }}
            </div>
          </div>
          <div class="main_theme_color curp" @click="lookMoreRatify">
            {{ $t("home.home.ckgd") }}
          </div>
        </div>
        <template v-if="casesObj.toPending && casesObj.toPending.length > 0">
          <div
            v-for="(item, caseIdx) in wrapRatifyList"
            :key="caseIdx"
            class="case_list_item curp"
            @click="goRatify(item)"
          >
            <div class="case_message">
              <div
                :style="{
                  backgroundImage: `url('${
                    item.userInfo && item.userInfo.photo
                      ? $PicPrefix + item.userInfo.photo
                      : defPhoto
                  }')`
                }"
                class="head_photo"
              ></div>
              <div>
                <div class="name txt-ellipsis">
                  {{ item.userInfo && item.userInfo.realName }}
                </div>
                <div class="case_number ml10 txt-ellipsis">
                  {{ item.caseNumber }}
                </div>
              </div>
            </div>
            <div class="product_name main_theme_color_999">
              {{ $t("casesDetail.casesDetail.sjjd") }} -
              {{
                item.cureType === "F"
                  ? $t("casesDetail.casesDetail.mxfa")
                  : $t("casesDetail.casesDetail.threedsj")
              }}{{ item.cureNoteNum }}
            </div>
            <div class="flex-x-y-c ml20">
              <span class="li_r_right_icon"></span>
            </div>
          </div>
        </template>
        <div v-else class="empty_type">
          <p class="empty_img to_ratify"></p>
          <span class="mt24 main_theme_color_666 fz14">{{
            $t("home.home.nhmydpz")
          }}</span>
        </div>
      </div>
      <div class="expressage_list posr">
        <div class="expressage_header">
          <div
            :class="{ expressage_header_tabs_active: +receivedStatus === 0 }"
            class="expressage_header_tabs"
            @click="selectCase('0')"
          >
            {{ $t("home.home.dsh") }}
            <div
              :class="{
                expressage_header_tabs_total: +waitReceivingObj.total < 10,
                expressage_header_tabs_total_two:
                  +waitReceivingObj.total <= 99 && +waitReceivingObj.total > 9,
                expressage_header_tabs_total_three: +waitReceivingObj.total > 99
              }"
              class="expressage_header_tabs_total_main flex-x-y-c"
            >
              {{
                +waitReceivingObj.total > 99 ? "99+" : waitReceivingObj.total
              }}
            </div>
          </div>
          <div
            :class="{ expressage_header_tabs_active: +receivedStatus === 1 }"
            class="expressage_header_tabs"
            @click="selectCase('1')"
          >
            {{ $t("home.home.ysh") }}
            <div
              :class="{
                expressage_header_tabs_total: +receivedObj.total < 10,
                expressage_header_tabs_total_two:
                  +receivedObj.total <= 99 && +receivedObj.total > 9,
                expressage_header_tabs_total_three: +receivedObj.total > 99
              }"
              class="expressage_header_tabs_total_main flex-x-y-c"
            >
              {{ +receivedObj.total > 99 ? "99+" : receivedObj.total }}
            </div>
          </div>
        </div>
        <template v-if="receivedList && receivedList.length > 0">
          <div
            v-infinite-scroll="load"
            class="expressage_list_content w100"
            style="overflow: auto"
          >
            <div
              v-for="(item, index) in receivedList"
              :key="index"
              class="expressage_list_item infinite-list"
              @click.prevent="jumpGoDetail(item)"
            >
              <img :src="$PicPrefix + item.userPhoto" alt="" />
              <div class="ml10 w100">
                <div class="flex-x-between">
                  <p class="user_name fz14 main_theme_color_333 txt-ellipsis">
                    {{ item.userName }}
                  </p>
                  <p
                    class="fz14 main_theme_color_red"
                    @click.stop="jumpDetail(item)"
                  >
                    {{ $t("home.home.kdzz") }}
                  </p>
                </div>
                <p
                  class="goods_name mt4 fz14 main_theme_color_333 txt-ellipsis"
                >
                  {{ item.logisticsName }}
                </p>
              </div>
            </div>
            <!--</el-scrollbar>-->
          </div>
        </template>
        <div v-else class="empty_type">
          <p class="empty_img to_expressage"></p>
          <span class="mt24 main_theme_color_666 fz14">{{
            $t("home.home.nzshmykd")
          }}</span>
        </div>
      </div>
    </div>
    <div v-show="isShowNewCase" class="new_case_product">
      <div
        v-for="(product, pdtx) in productList"
        :key="pdtx"
        :class="{ product_item_active: product.id === productId }"
        class="product"
        @mouseover="productHoverFn(product.systemType, product.productList)"
      >
        <p>{{ product.name }}</p>
        <div class="select_type">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="angle"></div>
    </div>
    <div v-show="productId" class="product_dialog">
      <!-- <img v-if="productId === '1'" class="product-dialog-bg" src="/static/images/home/create-logo-bg.webp" alt=""> -->
      <el-collapse
        v-if="productId === '1'"
        v-model="activeNames"
        class="product-collapse"
      >
        <el-collapse-item
          v-if="
            productParticularList.filter(item =>
              ['A', 'I'].includes(item.cureType)
            ).length
          "
          disabled
          name="1"
          title="成人"
        >
          <template slot="title">
            <p>成人</p>
            <span>-</span>
          </template>
          <div
            v-for="(item, index) in productParticularList.filter(item =>
              ['A', 'I'].includes(item.cureType)
            )"
            :key="index"
            class="product_item"
            @click="clickProductParticular(item)"
            @mouseleave="productParticularId = ''"
            @mouseover="productParticularId = item.id"
          >
            <p :title="item.name" class="product_item_name">{{ item.name }}</p>
            <el-popover
              :key="index + 'pop'"
              placement="bottom-end"
              trigger="hover"
              width="300"
            >
              <p class="mb10 fz12 main_theme_color_333">
                <i class="el-icon-warning-outline main_theme_color"> </i>
                {{ $t("common.common.jgwqy") }}
              </p>
              <p
                v-for="(uClinic, ucx) in item.unSignedClinicList"
                :key="ucx"
                class="fz12 main_theme_color_333"
              >
                {{ uClinic.name }}
              </p>
              <i
                v-show="
                  item.unSignedClinicList && item.unSignedClinicList.length > 0
                "
                slot="reference"
                class="product_item_init el-icon-warning-outline"
              ></i>
            </el-popover>
          </div>
        </el-collapse-item>
        <el-collapse-item
          v-if="
            productParticularList.filter(item =>
              ['B', 'C'].includes(item.cureType)
            ).length
          "
          disabled
          name="2"
          title="儿童"
        >
          <template slot="title">
            <p>儿童</p>
            <span>-</span>
          </template>
          <div
            v-for="(item, index) in productParticularList.filter(item =>
              ['B', 'C'].includes(item.cureType)
            )"
            :key="index"
            class="product_item"
            @click="clickProductParticular(item)"
            @mouseleave="productParticularId = ''"
            @mouseover="productParticularId = item.id"
          >
            <p :title="item.name" class="product_item_name">{{ item.name }}</p>
            <el-popover
              :key="index + 'pop'"
              placement="bottom-end"
              trigger="hover"
              width="300"
            >
              <p class="mb10 fz12 main_theme_color_333">
                <i class="el-icon-warning-outline main_theme_color"> </i>
                {{ $t("common.common.jgwqy") }}
              </p>
              <p
                v-for="(uClinic, ucx) in item.unSignedClinicList"
                :key="ucx"
                class="fz12 main_theme_color_333"
              >
                {{ uClinic.name }}
              </p>
              <i
                v-show="
                  item.unSignedClinicList && item.unSignedClinicList.length > 0
                "
                slot="reference"
                class="product_item_init el-icon-warning-outline"
              ></i>
            </el-popover>
          </div>
        </el-collapse-item>
        <el-collapse-item
          v-if="
            productParticularList.filter(
              item => !['A', 'B', 'C'].includes(item.cureType)
            ).length
          "
          disabled
          name="3"
          title="其他"
        >
          <template slot="title">
            <p>其他</p>
            <span>-</span>
          </template>
          <div
            v-for="(item, index) in productParticularList.filter(
              item => !['A', 'B', 'C', 'I'].includes(item.cureType)
            )"
            :key="index"
            class="product_item"
            @click="clickProductParticular(item)"
            @mouseleave="productParticularId = ''"
            @mouseover="productParticularId = item.id"
          >
            <p :title="item.name" class="product_item_name">{{ item.name }}</p>
            <el-popover
              :key="index + 'pop'"
              placement="bottom-end"
              trigger="hover"
              width="300"
            >
              <p class="mb10 fz12 main_theme_color_333">
                <i class="el-icon-warning-outline main_theme_color"> </i>
                {{ $t("common.common.jgwqy") }}
              </p>
              <p
                v-for="(uClinic, ucx) in item.unSignedClinicList"
                :key="ucx"
                class="fz12 main_theme_color_333"
              >
                {{ uClinic.name }}
              </p>
              <i
                v-show="
                  item.unSignedClinicList && item.unSignedClinicList.length > 0
                "
                slot="reference"
                class="product_item_init el-icon-warning-outline"
              ></i>
            </el-popover>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div
        v-for="(item, index) in productParticularList"
        v-else
        :key="index"
        class="product_item"
        @click="clickProductParticular(item)"
        @mouseleave="productParticularId = ''"
        @mouseover="productParticularId = item.id"
      >
        <p :title="item.name" class="product_item_name">{{ item.name }}</p>
        <el-popover
          :key="index + 'pop'"
          placement="bottom-end"
          trigger="hover"
          width="300"
        >
          <p class="mb10 fz12 main_theme_color_333">
            <i class="el-icon-warning-outline main_theme_color"> </i>
            {{ $t("common.common.jgwqy") }}
          </p>
          <p
            v-for="(uClinic, ucx) in item.unSignedClinicList"
            :key="ucx"
            class="fz12 main_theme_color_333"
          >
            {{ uClinic.name }}
          </p>
          <i
            v-show="
              item.unSignedClinicList && item.unSignedClinicList.length > 0
            "
            slot="reference"
            class="product_item_init el-icon-warning-outline"
          ></i>
        </el-popover>
      </div>
    </div>
    <div class="top_message">
      <div class="flex-y-c">
        <div class="vertical_moulding"></div>
        <img
          alt=""
          class="bell curp"
          src="../../common/imgs/home/message.png"
          @click="$router.push({ path: '/home/moreHomePage' })"
        />
        <span
          class="message curp"
          @click="$router.push({ path: '/home/moreHomePage' })"
          >{{ $t("home.home.xx") }}
        </span>
        <div
          :class="{
            expressage_header_tabs_total: +msgObj.total < 10,
            expressage_header_tabs_total_two:
              +msgObj.total <= 99 && +msgObj.total > 9,
            expressage_header_tabs_total_three: +msgObj.total > 99
          }"
          class="top_message_total expressage_header_tabs_total_main flex-x-y-c"
        >
          {{ +msgObj.total > 99 ? "99+" : msgObj.total }}
        </div>
      </div>

      <div
        class="message_look_all"
        @click="$router.push({ path: '/home/moreHomePage' })"
      >
        <span>{{ $t("home.home.ckqb") }}</span>
      </div>
    </div>
    <div class="inform_message">
      <msg-page :msgList="msgObj.list" />
    </div>
    <div class="top_message">
      <div class="flex-y-c">
        <div class="vertical_moulding"></div>
        <img
          alt=""
          class="curp"
          src="../../common/imgs/home/laba.png"
          style="width: 0.32rem; height: 0.32rem"
          @click="backBottom()"
        />
        <span class="message curp" @click="backBottom()">{{
          $t("home.home.xtgg")
        }}</span>
        <div
          :class="{
            expressage_header_tabs_total: +announcementTotal < 10,
            expressage_header_tabs_total_two:
              +announcementTotal <= 99 && +announcementTotal > 9,
            expressage_header_tabs_total_three: +announcementTotal > 99
          }"
          class="top_message_total expressage_header_tabs_total_main flex-x-y-c"
        >
          {{ +announcementTotal > 99 ? "99+" : announcementTotal }}
        </div>
      </div>
      <div
        class="message_look_all"
        @click="$router.push({ path: '/home/Notice' })"
      >
        <span>{{ $t("home.home.ckqb") }}</span>
      </div>
    </div>
    <div class="home_container">
      <div class="announcement_box">
        <div class="announcement_title">
          <p class="title_01">{{ $t("home.home.ggbt") }}</p>
          <p class="title_02 tac">{{ $t("home.home.sj") }}</p>
          <p></p>
        </div>
        <ul
          v-if="announcementList.length"
          class="announcement_ul"
          @click="announcementDetail"
        >
          <li
            v-for="(item, index) in announcementList"
            :key="index"
            class="an_single"
          >
            <div :data-key="item.id" class="click_box"></div>
            <p
              :title="item.title"
              class="announcement_ul_title_01 txt-ellipsis"
            >
              {{ item.title }}
            </p>
            <p class="announcement_ul_title_02 txt-ellipsis">
              {{ item.updateDatetime }}
            </p>
          </li>
        </ul>
        <div
          v-else
          :class="{ ann_no_empty: !announcementList.length }"
          class="ann_no"
        >
          <img alt="" src="../../common/imgs/home/empty_announcement.png" />
          <span class="fz14 main_theme_color_666"
            >{{ $t("home.home.nzshmygg") }}～</span
          >
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('home.home.dpzfa')"
      :visible.sync="ratifyDialogVisible"
      width="8.3rem"
      @closed="closedRatifyDialogVisible"
    >
      <div class="ratify_dia_content">
        <div class="ratify_list_content">
          <el-scrollbar style="height: 100%">
            <div
              v-for="(item, index) in toRatifyList"
              :key="index"
              class="ratify_list_item flex-y-c"
              @click="goRatify(item)"
            >
              <div class="flex-y-c ratify_list_item_user">
                <img :src="$PicPrefix + item.userInfo.photo" alt="" />
                <p class="txt-ellipsis">{{ item.userInfo.realName }}</p>
              </div>
              <div class="case_number txt-ellipsis">{{ item.caseNumber }}</div>
              <div
                :class="{ 'txt-ellipsis': language !== 'en_US' }"
                class="case_stage main_theme_color_333"
              >
                {{ $t("casesDetail.casesDetail.sjjd") }} -
                {{
                  item.cureType === "F"
                    ? $t("casesDetail.casesDetail.mxfa")
                    : $t("casesDetail.casesDetail.threedsj")
                }}{{ item.cureNoteNum }}
              </div>
              <img
                alt=""
                class="black_arrows"
                src="../../common/imgs/home/black_arrows.png"
              />
            </div>
          </el-scrollbar>
        </div>
        <div class="ratify_dia_footer">
          <el-pagination
            v-if="!isLoading"
            :current-page="ratifyQuery.pageNum"
            :page-size="ratifyQuery.pageSize"
            :page-sizes="[10, 20, 30, 50]"
            :total="toRatifyListTotal"
            background
            layout="total, sizes, prev, pager, next"
            @size-change="
              getRatifyList({
                ratifyPageSize: arguments[0],
                ratifyPageNum: 1
              })
            "
            @current-change="
              getRatifyList({
                ratifyPageNum: arguments[0]
              })
            "
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
let once = function(ele, eventName, fn) {
  let callback = function(...args) {
    ele.removeEventListener(eventName, callback);
    fn(...args);
  };
  ele.addEventListener(eventName, fn, false);
  return () => ele.removeEventListener(eventName, callback);
};
import { getSystormKey } from "common/api/public";
import MsgPage from "./publicChildren/msgPage";
import FullLoading from "components/full-loading/full-loading";
import vueSeamless from "vue-seamless-scroll";
import { clearUser, getUserId, removeCreate, notifyMsg } from "common/js/util";
import { mapState, mapMutations, mapActions } from "vuex";
import { querySmsList } from "common/api/system";
import { getProductTypeListByDoctor } from "common/api/public";
import {
  queryCasesList,
  queryCasesPage,
  PublicLogisticsCasePage,
  clinicDoctorList,
  casesStatistical,
  caseMessagePage
} from "common/api/cases";
import { defPhoto } from "common/js/requireImage";
import { extend, find, get, pickBy } from "lodash";
import $ from "jquery";
import { getDictList } from "common/api/public";

export default {
  data() {
    return {
      activeNames: ["1", "2", "3"],
      clickFn: "",
      isLoading: false,
      ratifyDialogVisible: false,
      defPhoto,
      statusList: ["to_receive_done", "to_receive", "to_receive_remain"],
      nextStatusObj: {},
      casesObj: {
        toSubmitList: [],
        toPending: [],
        toSubmitListTotal: 0,
        toPendingTotal: 0
      },
      toRatifyList: [],
      toRatifyListTotal: 0,
      announcementList: [],
      announcementTotal: "",
      clinicList: [],
      casesCountObj: {},
      doctorId: getUserId(),
      isShowNewCase: false,
      productList: [],
      productListState: "",
      productId: "",
      productParticularId: "",
      productParticularList: [],
      msgObj: {
        list: [],
        total: ""
      },
      wrapRatifyList: [],
      wrapRatifyListTotal: 0,
      waitReceivingObj: {
        list: [],
        total: 0
      },
      receivedObj: {
        list: [],
        total: 0
      },
      number: 1,
      receivedPageSize: 10,
      receivedStatus: 0
    };
  },
  computed: {
    ...mapState({
      userMsg: state => state.userMsg,
      isHandleMsg: state => state.isHandleMsg,
      isShowNews: state => state.isShowNews,
      language: state => state.language
    }),
    selectedClinicId: {
      get() {
        return (
          this.$route.query.selectedClinicId ||
          sessionStorage.getItem("clinicId")
        );
      },
      set(id) {
        sessionStorage.setItem("clinicId", id);
      }
    },
    ratifyQuery() {
      let query = this.$route.query;
      return {
        pageNum: +query.ratifyPageNum || 1,
        pageSize: +query.ratifyPageSize || 10,
        clinicId: this.selectedClinicId,
        doctorId: this.doctorId,
        status: "to_handle",
        nextStatus: "to_confirm_plan"
      };
    },
    receivedList() {
      return +this.receivedStatus === 0
        ? this.waitReceivingObj.list
        : this.receivedObj.list;
    }
  },
  methods: {
    ...mapMutations(["GET_IS_SHOW_NEWS", "HANDLE_MSG"]),
    ...mapActions(["getUserMsg"]),
    get,
    jumpGoDetail(item) {
      let { caseId, curePlanId } = item;
      this.openPage(`/cases/detail?caseId=${caseId}&curePlanId=${curePlanId}`);
    },
    jumpDetail(item) {
      let { caseId, id } = item;
      window.open(`/cases/viewLogistics?logisticsId=${id}&caseId=${caseId}`);
    },
    load() {
      this.receivedPageSize += 10;
      if (this.receivedStatus == 0 || !this.receivedStatus) {
        this.getLogisticsCasePage(
          this.receivedPageSize,
          this.receivedStatus + ""
        );
      } else {
        this.getLogisticsCasePageReceived(
          this.receivedPageSize,
          this.receivedStatus + ""
        );
      }
    },
    closedRatifyDialogVisible() {
      let route = this.$route;
      this.$router.replace({
        path: route.path,
        query: extend({}, { ratifyPageNum: "", ratifyPageSize: "" })
      });
    },
    goSubmit(item) {
      let caseId = item.id,
        curePlanId = item.currentCurePlanId || "",
        productType = item.cureType,
        productId = item.productId;
      let route = this.$route;
      this.$router.replace({
        path: "/cases/create",
        query: extend(
          {},
          route.query,
          pickBy({
            caseId,
            curePlanId,
            productType,
            productId
          })
        )
      });
    },
    goRatify(item) {
      let caseId = item.id,
        curePlanId = item.currentCurePlanId,
        cureNoteId = item.cureNoteId;
      if (item.cureType === "F") {
        window.open(
          "/exocad" +
            `?caseId=${caseId}&curePlanId=${curePlanId}&schemeType=cure&cureNoteId=${cureNoteId}`
        );
      } else {
        window.open(
          "/cases/view3d" +
            `?caseId=${caseId}&curePlanId=${curePlanId}&schemeType=cure&cureNoteId=${cureNoteId}`
        );
      }
    },
    lookMoreRatify() {
      this.getRatifyList().then(() => {
        this.ratifyDialogVisible = true;
      });
    },
    //产品类型hover
    productHoverFn(id, list) {
      this.productId = id;
      this.productParticularList = list;
    },
    // 新增产品按钮点击
    inquireProductList() {
      this.isShowNewCase = true;
      this.getProductList();
    },
    //产品点击跳转
    clickProductParticular(item) {
      let { id, cureType } = item;
      this.productParticularId = id;
      window.open(`/cases/create?productId=${id}`);

      this.productId = "";
      this.productParticularId = "";
      this.isShowNewCase = false;
    },
    jumpCase(type) {
      let clinicId = sessionStorage.getItem("clinicId");
      this.$router.replace({
        path: "/cases",
        query: { type, clinicId }
      });
    },
    backBottom() {
      var height = $(document).height();
      $("html, body").animate({ scrollTop: height }, "fast");
    },
    lookAll(type) {
      sessionStorage.setItem("selectedKey", type);
      this.goPage(`/cases?pageNum=1&type=${type}`);
    },
    //切换tabs
    selectCase(type) {
      this.receivedStatus = type;
      this.receivedPageSize = 10;
    },
    getLogisticsCasePage(pageSize = 10, status = "0") {
      this.isLoading = true;
      PublicLogisticsCasePage(
        pickBy({
          pageNum: 1,
          pageSize,
          clinicId: this.selectedClinicId,
          doctorId: this.doctorId,
          generateFlag: 1,
          status,
          pageType: "index",
          refType: 2
        })
      )
        .then(data => {
          this.isLoading = false;
          this.waitReceivingObj.list = data.list;
          this.waitReceivingObj.total = data.total;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getLogisticsCasePageReceived(pageSize = 10, status = "1") {
      this.isLoading = true;
      PublicLogisticsCasePage(
        pickBy({
          pageNum: 1,
          pageSize,
          clinicId: this.selectedClinicId,
          doctorId: this.doctorId,
          generateFlag: 1,
          status,
          pageType: "index",
          refType: 2
        })
      )
        .then(data => {
          this.isLoading = false;
          this.receivedObj.list = data.list;
          this.receivedObj.total = data.total;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    jumpPage(arg) {
      let route = this.$route;
      this.$router.push(
        pickBy({
          path: route.path,
          query: extend({}, route.query, arg)
        })
      );
    },
    getMoreLogisticsCasePage(arg) {
      let route = this.$route;
      this.$router.replace({
        path: route.path,
        query: extend({}, this.$route.query, arg)
      });
      if (+this.receivedStatus === 0) {
        this.getLogisticsCasePage();
      } else {
        this.getLogisticsCasePageReceived();
      }
    },
    caseMessagePage() {
      caseMessagePage({
        toUserId: this.doctorId,
        status: "to_handle",
        pageNum: 1,
        pageSize: 5
      }).then(data => {
        this.msgObj.list = data.list || [];
        this.msgObj.total = data.total || "";
      });
    },
    openPage(url) {
      window.open(url, "_blank");
    },
    casesStatistical() {
      casesStatistical({
        clinicId: this.selectedClinicId || "",
        doctorId: this.doctorId || ""
      }).then(data => {
        const obj = {
          to_handle: 0,
          curing: 0,
          done: 0
        };
        data.forEach(item => {
          if (obj[item.status] === 0) {
            obj[item.status] = item.count;
          }
        });
        this.casesCountObj = obj;
      });
    },
    queryCasesPage() {
      this.isLoading = true;
      this.casesStatistical();
      queryCasesPage({
        clinicId: this.selectedClinicId,
        doctorId: this.doctorId,
        status: "to_handle",
        pageNum: 1,
        pageSize: 4
      })
        .then(data => {
          this.isLoading = false;
          const obj = {
            toPending: [],
            toPendingTotal: 0
          };
          obj.toPending = data.list;
          obj.toPendingTotal = data.total;
          this.casesObj = extend({}, this.casesObj, obj);
        })
        .catch(() => {
          this.isLoading = false;
        });
      queryCasesPage({
        clinicId: this.selectedClinicId,
        doctorId: this.doctorId,
        pageNum: 1,
        pageSize: 5,
        status: "precuring"
      })
        .then(data => {
          this.isLoading = false;
          const obj = {
            toSubmitList: []
          };
          obj.toSubmitList = data.list;
          obj.toSubmitListTotal = data.total;
          this.casesObj = extend({}, this.casesObj, obj);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getRatifyListWrap() {
      queryCasesPage({
        clinicId: this.selectedClinicId,
        doctorId: this.doctorId,
        status: "to_handle",
        pageNum: 1,
        pageSize: 5,
        nextStatus: "to_confirm_plan"
      })
        .then(data => {
          this.isLoading = false;
          this.wrapRatifyList = data.list;
          this.wrapRatifyListTotal = data.total;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getRatifyList(arg) {
      this.isLoading = true;
      let route = this.$route;
      this.$router.replace({
        path: route.path,
        query: extend({}, route.query, arg)
      });
      return queryCasesPage(this.ratifyQuery)
        .then(data => {
          this.toRatifyList = data.list;
          this.toRatifyListTotal = data.total;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    clinicChange(id) {
      let route = this.$route;
      this.$router.replace({
        path: route.path,
        query: extend({}, route.query, { selectedClinicId: id })
      });
      let obj = find(this.clinicList, item => item.clinicId === id);
      if (get(obj, "clinicInfo.rdtId")) {
        sessionStorage.setItem("IRTD", get(obj, "clinicInfo.rdtId"));
      }
      this.getProductList();
      this.queryCasesPage();
      this.getRatifyListWrap();
      this.getLogisticsCasePageReceived();
      this.getLogisticsCasePage();
    },
    getProductList() {
      if (this.productListState === "loading") return;
      let clinicId = this.$route.query.selectedClinicId;
      this.productListState = "loading";
      return getProductTypeListByDoctor(
        pickBy({ clinicId: clinicId, platformType: "0" })
      )
        .then(data => {
          console.log("data", data);
          this.productList = data;
          this.productListState = "loadend";
          if (!data.length) {
            notifyMsg(this, "warning", this.$t("home.home.zwqycp"), 2000);
          }
        })
        .catch(() => {
          this.productListState = "loaderr";
        });
    },
    selectClear() {
      sessionStorage.removeItem("clinic");
      sessionStorage.removeItem("IRTD");
    },

    detailJump(item) {
      let { id, userName, currentCurePlanId } = item;
      localStorage.setItem(
        "windowName",
        userName + this.$t("common.common.blxq")
      );
      this.openPage(
        `/cases/detail?caseId=${id}&curePlanId=${currentCurePlanId}`
      );
    },
    announcementDetail(ev) {
      const target = ev.target;
      const list = Array.from(target.classList);
      if (list.includes("click_box")) {
        const key = target.getAttribute("data-key");
        sessionStorage.setItem("announcementId", key);
        this.goPage("/home/msgDetail");
      }
    },
    goPage(url) {
      if (url) {
        this.$router.push({
          path: url,
          query: extend({}, this.$route.query)
        });
      }
    }
  },
  mounted() {
    this.clickFn = once(document, "click", e => {
      if (
        ![
          "select_type",
          "select_type select_product",
          "new_case_product",
          null,
          "product product_item_active",
          "el-icon-arrow-right",
          "product_item_active",
          "product",
          "main_theme_color_333 curp ml10 add_btn",
          "curp add_btn",
          "create-btn",
          "el-collapse-item__header focusing",
          "el-collapse-item is-active",
          "el-collapse-item__header is-active",
          "el-collapse product-collapse",
          "el-collapse-item__header",
          "el-collapse-item",
          "el-collapse-item__arrow el-icon-arrow-right is-active",
          "el-collapse-item__arrow el-icon-arrow-right"
        ].includes(e.target.getAttribute("class"))
      ) {
        this.isShowNewCase = false;
      }
    });
  },
  created() {
    this.HANDLE_MSG();
    this.getUserMsg();
    document.title = this.$t("home.home.title");
    document.body.style.height = "auto";
    removeCreate();
    this.getRatifyListWrap();
    this.getLogisticsCasePage();
    this.getLogisticsCasePageReceived();
    getDictList("case.next_status").then(data => {
      const obj = {};
      data.forEach(item => {
        obj[item.key] = item.remark;
      });
      this.nextStatusObj = obj;
    });
    this.queryCasesPage();
    querySmsList({
      status: "1",
      target: "DOCTOR",
      type: "SYS",
      readFlag: "0",
      language: localStorage.getItem("user_lang") || "zh_CN"
    }).then(data => {
      this.announcementTotal = data.length;
      this.announcementList = data.splice(0, 3);
    });
    clinicDoctorList({
      doctorId: this.doctorId,
      status: "1"
    }).then(data => {
      this.clinicList = data.map(item => ({
        clinicId: item.clinicId,
        clinicName: item.clinicInfo
          ? `${item.clinicInfo.name}${
              item.clinicInfo.enName ? `(${item.clinicInfo.enName})` : ""
            }`
          : "-",
        clinicInfo: item.clinicInfo
      }));
    });
    this.caseMessagePage();
    getSystormKey("tooth_view_prefix").then(data => {
      localStorage.setItem("viewPrefix", data[0] ? data[0].value : "");
    });
  },
  destroyed() {
    this.clickFn();
  },
  components: {
    vueSeamless,
    MsgPage,
    FullLoading
  },
  watch: {
    isHandleMsg() {
      this.caseMessagePage();
    },
    isShowNewCase(val) {
      if (!val) {
        this.productId = "";
        this.productParticularId = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ratify_dia_content {
  width: 100%;
  height: 542px;

  .ratify_list_content {
    width: 100%;
    height: 462px;

    .ratify_list_item {
      width: 100%;
      height: 84px;
      padding: 20px 40px;
      cursor: pointer;
      transition: all 0.2s;

      .ratify_list_item_user {
        img {
          display: block;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          margin-right: 10px;
        }

        p {
          width: 0.84rem;
          line-height: 0.25rem;
          color: #333333;
        }
      }

      .case_number {
        width: 0.84rem;
        margin-left: 140px;
        color: #333333;
      }

      .case_stage {
        margin-left: 120px;
        width: 1.6rem;
      }

      .black_arrows {
        display: block;
        width: 16px;
        margin-left: 80px;
      }
    }

    .ratify_list_item:hover {
      background: #f9f9f9;
      transition: all 0.2s;
    }
  }

  .ratify_dia_footer {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
    border-radius: 0 0 8px 8px;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    padding-right: 40px;
  }
}

/deep/ .el-popover {
  min-width: 5rem !important;
}

/deep/ .el-input__inner {
  height: 0.32rem !important;
}

/*删除了banner*/
/*margin: -4.25rem auto 0 auto;*/
.home_page {
  width: 13.44rem;
  margin: -4.25rem auto 0 auto;
  color: #333333;
  font-size: 0.16rem;
  transition: all 0.3s;

  .case_content {
    margin-top: 20px;
    width: 100%;
    height: 474px;
    display: flex;

    .case_list {
      width: 4.36rem;
      height: 100%;
      background: #ffffff;
      border-radius: 6px;

      .case_list_header {
        width: 100%;
        height: 53px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
      }

      .case_list_item {
        width: 100%;
        height: 84px;
        transition: all 0.2s;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;

        .case_message {
          width: 1.38rem;
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;
          font-size: 14px;

          .head_photo {
            max-width: 0.44rem;
            min-width: 0.44rem;
            height: 0.44rem;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          .name {
            font-size: 14px;
            width: 0.9rem;
            height: 0.2rem;
            color: $main_theme_color_333;
            margin-left: 0.1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .case_number {
            width: 0.9rem;
          }
        }

        .product_name {
          width: 1.52rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 0.14rem;
          line-height: 0.88rem;
          color: $main_theme_color_333;
          margin-left: 0.5rem;
        }

        .li_r_right_icon {
          display: inline-block;
          width: 0.16rem;
          height: 0.16rem;
          background-image: url("../../common/imgs/home/icon_arrow.png");
          background-size: 100% 100%;
        }
      }

      .case_list_item:hover {
        transition: all 0.2s;
        background: #f9f9f9;
      }
    }

    .expressage_list {
      margin-left: 20px;
      width: 432px;
      height: 100%;
      background: #ffffff;
      border-radius: 6px;

      .expressage_header {
        width: 100%;
        height: 53px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        padding: 0 20px;

        & > div:nth-child(2) {
          margin-left: 43px;
        }

        .expressage_header_tabs {
          display: flex;
          align-items: center;
          height: 100%;
          cursor: pointer;
          transition: all 0.2s;
          border-bottom: 2px solid white;
          color: $main_theme_color_999;
          font-size: 16px;
        }

        .expressage_header_tabs_active {
          border-bottom: 2px solid $main_theme_color;
          color: $main_theme_color_333;
          transition: all 0.2s;
        }
      }

      .expressage_list_content {
        height: 418px;

        .expressage_list_item {
          width: 392px;
          height: 84px;
          background: #f4f4f4;
          border-radius: 6px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          margin: 20px auto;
          cursor: pointer;

          .user_name {
            width: 1rem;
            line-height: 0.2rem;
          }

          .goods_name {
            width: 2.9rem;
            line-height: 0.2rem;
          }

          img {
            display: block;
            min-width: 44px;
            max-width: 44px;
            min-height: 44px;
            max-height: 44px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .user_content {
    height: 4.68rem;
    display: flex;
    position: relative;

    .user_info {
      width: 4.9rem;
      background-color: #fff;
      height: 100%;
      position: relative;

      .user_bg {
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
      }

      .user {
        width: 100%;
        height: 1.5rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin-top: 0.92rem;
        position: relative;
        z-index: 999;

        img {
          width: 1.28rem;
          height: 1.28rem;
          border-radius: 50% !important;
        }
      }

      .user_name {
        font-size: 0.22rem;
        color: $main_theme_color_333;
        height: 30px;
        margin-top: 0.12rem;
      }

      .clinic {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.22rem;

        span {
          font-size: 0.16rem;
          color: #9b9b9b;
        }
      }

      .case {
        width: 100%;
        margin-top: 0.15rem;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 10px;
        left: 0;

        > div {
          width: 50%;
          height: 0.8rem;
          border-bottom: none;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          cursor: pointer;
          transition: all 0.2s;

          span {
            font-size: 0.26rem;
            font-weight: bold;
            color: $main_theme_color;
          }

          div {
            font-size: 0.12rem;
            font-weight: 500;
            color: #666666;
            margin-top: 0.1rem;
          }
        }

        > div:hover {
          background: #f9f9f9;
          border-radius: 6px;
          transition: all 0.2s;
        }

        .vertical_moulding {
          width: 1px;
          height: 40px;
          background: #d8d8d8;
          margin-top: 20px;
        }
      }
    }

    .case_info {
      width: 8.34rem;
      height: 100%;
      background-color: white;
      margin-left: 0.2rem;
      position: relative;
      border-radius: 6px;

      .look_all {
        display: flex;
        align-items: center;
        margin-right: 30px;

        img {
          margin-left: 0.8rem;
          cursor: pointer;
        }

        span {
          font-size: 14px;
          font-weight: 500;
          color: $main_theme_color;
          margin-left: 0.14rem;
          cursor: pointer;
        }
      }

      .tabs {
        height: 0.53rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.01rem solid #dcdfe6;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          font-size: 0.18rem;
          font-weight: 500;
          color: $main_theme_color_333;
          position: relative;
          cursor: pointer;
          margin-left: 30px;
        }
      }

      .case {
        width: 100%;
        height: 0.84rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 0.3rem;
        transition: all 0.2s;

        .case_message {
          width: 1.54rem;
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;

          .head_photo {
            max-width: 0.44rem;
            min-width: 0.44rem;
            height: 0.44rem;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          .name {
            width: 1rem;
            height: 0.2rem;
            color: $main_theme_color_333;
            margin-left: 0.1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
          }
        }

        .product_name {
          width: 1.9rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          line-height: 0.88rem;
          color: $main_theme_color_333;
          margin-left: 0.6rem;
        }

        .case_number {
          width: 84px;
          margin-left: 60px;
          font-size: 14px;
          color: $main_theme_color_333;
        }

        .case_status {
          width: 100px;
          line-height: 0.25rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-left: 132px;
          margin-right: 10px;
          font-size: 14px;
        }

        > p {
          width: 5%;
        }

        .li_r_right_icon {
          display: inline-block;
          width: 0.16rem;
          height: 0.16rem;
          background-image: url("../../common/imgs/home/icon_arrow.png");
          background-size: 100% 100%;
        }
      }

      .case:hover {
        transition: all 0.2s;
        background: #f9f9f9;
      }

      .bottom_info {
        width: 100%;
        height: 0.62rem;
        position: absolute;
        bottom: 0;
        display: flex;
        box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
        justify-content: center;
        align-items: center;

        .create-btn {
          cursor: pointer;
          width: 312px;
          height: 48px;
          background-color: #fcc80e;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
          }
        }
      }
    }
  }

  .new_case_product {
    max-height: 600px;
    overflow-y: auto;
    width: 2.78rem;
    overflow-y: auto;
    max-height: 400px;
    border-radius: 0.05rem;
    background-color: white;
    position: absolute;
    left: 11.16rem;
    top: 4.7rem;
    z-index: 99;
    box-shadow: 0 0 0.16rem 0 rgba(167, 167, 167, 0.35);

    .product {
      width: 100%;
      height: 0.52rem;
      padding: 0.16rem 0.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 9;
      cursor: pointer;
      font-size: 14px;
      color: $main_theme_color_333;

      &:hover {
        background: #f9f9f9;
        color: $main_theme_color;
      }

      p {
        width: 80%;
        line-height: 0.23rem;
      }
    }

    .angle {
      width: 0.18rem;
      height: 0.1rem;
      background-image: url("../../../src/common/imgs/home/top_corner_mark.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: -0.1rem;
      left: 0.42rem;
    }
  }

  .product_dialog {
    max-height: 600px;
    overflow-y: auto;
    width: 2.9rem;
    display: inline;
    border-radius: 0.05rem;
    background-color: white;
    position: absolute;
    left: 14rem;
    top: 4.7rem;
    box-shadow: 0 0 0.16rem 0 rgba(167, 167, 167, 0.35);
    z-index: 99;
    transition: all 0.1s;

    .product-dialog-bg {
      width: 120px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .product-collapse {
      max-height: 600px;
      overflow-y: auto;
      color: #333333 !important;

      /deep/ .el-collapse-item__header {
        flex-direction: row-reverse;
        justify-content: flex-end;
        border-bottom: none;
        padding-left: 0.16rem;
        color: #333333 !important;
        cursor: pointer !important;
        position: relative;
        background-color: transparent !important;

        span {
          font-size: 8px;
          margin-right: 8px;
        }

        .el-collapse-item__arrow {
          display: none;
        }

        &::before {
          content: "";
          width: 24px;
          height: 6px;
          border-radius: 3px;
          background-color: #fcc80e;
          position: absolute;
          bottom: 50%;
          left: 35px;
          z-index: 1;
          opacity: 0.8;
          transform: translateY(100%);
        }

        p {
          position: relative;
          z-index: 2;
        }
      }

      /deep/ .el-collapse-item__arrow {
        margin: 0 12px 0 0;
      }

      /deep/ .el-collapse-item {
        &:last-child {
          .el-collapse-item__content {
            .product_item {
              &:last-child {
                p {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      /deep/ .el-collapse-item__content {
        padding: 0px;

        .product_item {
          padding: 0px 0.16rem;
          border-bottom: none;

          p {
            padding: 0.16rem 0;
            height: 100%;
          }

          &:last-child {
            p {
              border-bottom: 1px solid #e5e5e5;
            }
          }
        }
      }

      /deep/ .el-collapse-item__wrap {
        border-bottom: none;
      }
    }

    .product_item {
      width: 100%;
      line-height: 0.18rem;
      padding: 0.16rem 0.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: $main_theme_color;
        background: #f9f9f9;
      }

      .product_item_name {
        width: 100%;
        margin-right: 0.2rem;
      }

      .product_item_init {
        cursor: pointer;
      }

      .angle {
        width: 0.07rem;
        height: 0.07rem;
        background-image: url("../../../src/common/imgs/home/black_corner_mark.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 0;
      }

      .circle_black_corner_mark {
        background-image: url("../../../src/common/imgs/home/circle_black_corner_mark.png") !important;
      }

      .active_yellow_corner_mark {
        background-image: url("../../../src/common/imgs/home/yellow_corner_mark.png") !important;
      }

      .circle_active_yellow_corner_mark {
        background-image: url("../../../src/common/imgs/home/circle_active_yellow_corner_mark.png") !important;
      }
    }
  }

  .top_message {
    margin-top: 0.22rem;
    margin-bottom: 0.28rem;
    width: 100%;
    height: 0.38rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .top_message_total {
      position: absolute;
      left: 26px;
      top: 5px;
    }

    .message_look_all {
      display: flex;

      span {
        margin-left: 0.1rem;
        font-size: 0.14rem;
        color: $main_theme_color;
        cursor: pointer;
      }
    }

    .vertical_moulding {
      width: 0.03rem;
      height: 100%;
      background-color: $main_theme_color;
    }

    .bell {
      width: 0.32rem;
      height: 0.32rem;
    }

    .message {
      font-size: 0.18rem;
      font-weight: 500;
      color: $main_theme_color_333;
      margin-left: 0.17rem;
    }
  }

  .inform_message {
    background-color: white;
    width: 100%;
  }

  .home_container {
    width: 100%;
    margin: 0 auto;
    background-color: white;

    .con_head {
      margin-bottom: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .con_head_left {
        display: flex;
        align-items: center;
        position: relative;

        .h_left_h5 {
          margin-right: 0.68rem;
        }

        .h5_strong {
          font-size: 0.23rem;
          margin: 0 0.04rem;
        }

        .h_left_select {
          border: none;
          outline: none;
          padding: 0.1rem;
        }

        .l_select_icon {
          position: absolute;
          right: -1.5%;
          bottom: 38%;
          display: inline-block;
          width: 0.1rem;
          height: 0.05rem;
          background-size: 100% 100%;
          background-image: url("/static/images/home/web_hp_arrow.png");
        }
      }

      .con_head_right {
        width: 1.48rem;
        height: 0.44rem;
        background: rgb(81, 30, 122);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        cursor: pointer;

        .add_icon {
          display: inline-block;
          font-size: 0.18rem;
          font-weight: bold;
          margin-right: 0.06rem;
        }

        .h_r_txt {
          padding-top: 0.02rem;
        }
      }
    }

    .cases_ul {
      margin-bottom: 0.3rem;
      display: flex;
      justify-content: space-between;

      .cases_single {
        width: 32%;
        height: 2.92rem;
        box-sizing: border-box;
        padding: 0.2rem 0.18rem 0.3rem;
        background-color: #fff;
        border-radius: 0.06rem;
        display: flex;
        flex-direction: column;

        .single_h5 {
          display: flex;
          justify-content: space-between;
          padding-bottom: 0.1rem;

          .single_h5_l {
            font-size: 0.15rem;
          }

          .single_h5_r {
            color: #511e7a;
            font-size: 0.14rem;
            cursor: pointer;
          }
        }

        .c_single_li {
          display: flex;
          align-items: center;
          padding: 0.2rem 0;
          border-bottom: 0.01rem solid #ebebeb;
          cursor: pointer;
          position: relative;

          &:last-child {
            border: none;
          }

          .c_single_li_pic {
            width: 0.38rem;
            height: 0.38rem;
            margin-right: 0.07rem;
            border-radius: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          .c_single_li_r {
            flex: 1;

            .li_r_left_h5 {
              font-size: 0.15rem;
              margin-bottom: 0.08rem;
            }

            .li_r_left_p {
              color: #999;
              font-size: 0.14rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }

        .cases_no {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #999999;
          font-size: 0.15rem;

          .cases_no_icon {
            width: 0.48rem;
            height: 0.54rem;
            margin-bottom: 0.13rem;
            background-size: 100% 100%;
          }

          .cases_no_icon01 {
            background-image: url("/static/images/home/web_hp_disposal.png");
          }

          .cases_no_icon02 {
            background-image: url("/static/images/home/web_hp_treatment.png");
          }

          .cases_no_icon03 {
            background-image: url("/static/images/home/web_hp_completed.png");
          }
        }
      }
    }

    .page_h5 {
      display: flex;
      align-items: center;
      font-size: 0.22rem;
      font-family: Microsoft YaHei;
      margin-bottom: 0.29rem;

      .page_h5_line {
        display: inline-block;
        width: 0.03rem;
        height: 0.22rem;
        margin-right: 0.1rem;
        background-color: #ff8e30;
      }
    }

    .announcement_box {
      margin-bottom: 0.87rem;
      color: #9b9b9b;

      .announcement_title {
        display: flex;
        align-items: center;
        height: 0.54rem;
        font-size: 0.18rem;
        border-bottom: 0.01rem solid #dcdfe6;
      }

      .announcement_ul {
        background-color: #fff;

        .an_single {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 0.6rem;
          font-size: 0.14rem;
          color: $main_theme_color_333;
          border-bottom: 0.01rem solid #e9e9e9;
          position: relative;

          .announcement_ul_title_01 {
            width: 600px;
            padding-left: 60px;
          }

          .announcement_ul_title_02 {
            padding-right: 60px;
          }
        }

        .an_single:hover {
          transition: 0.3s all;
          background: #f9f9f9;
          color: #555657;
        }
      }

      .announcement_ul_empty {
        height: 0.6rem;
      }

      .ann_no {
        color: #666666;
        font-size: 0.16rem;
        height: 0.88rem;
        line-height: 1.1rem;
        padding-left: 0.78rem;
        background-color: #fff;
      }

      .ann_no_empty {
        height: 3.38rem !important;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          width: 198px;
          height: 142px;
        }
      }

      .title_01 {
        flex: 4;
        padding-left: 0.6rem;
        font-size: 16px;
        color: $main_theme_color_333;
      }

      .title_02 {
        flex: 1;
        font-size: 16px;
        color: $main_theme_color_333;
        display: flex;
        flex-flow: row-reverse;
        margin-right: 60px;
      }
    }
  }
}

.home_page_news {
  margin-top: -4.62rem;
}

.product_item_active {
  color: $main_theme_color;
  background-color: #f2f2f2;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-scrollbar__bar {
  opacity: 0 !important;
}

.empty_type {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;

  .empty_img {
    background-image: url("../../common/imgs/home/to_dispose.png");
    width: 2.06rem;
    height: 1.87rem;
    background-size: 100% 100%;
  }

  .to_submit_img {
    background-image: url("../../common/imgs/home/to_submit.png");
  }

  .to_ratify {
    background-image: url("../../common/imgs/home/to_ratify.png");
  }

  .to_expressage {
    background-image: url("../../common/imgs/home/to_expressage.png");
  }
}

.expressage_header_tabs_total_main {
  height: 14px;
  background: #ed4027;
  border-radius: 7px;
  font-size: 12px;
  color: white;
  padding-left: 4px;
  text-align: center;
}

.expressage_header_tabs_total {
  width: 14px;
}

.expressage_header_tabs_total_two {
  width: 22px;
}

.expressage_header_tabs_total_three {
  width: 28px;
}

/deep/ .el-dialog__header {
  padding: 16px 20px;
  border-bottom: 1px solid #e5e5e5;
}

/deep/ .el-dialog__title {
  color: $main_theme_color_333;
}

/deep/ .el-dialog__body {
  padding: 0 !important;
}
</style>
